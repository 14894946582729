<template>
  <div>
    <ca-header
      heading="Antrag abschließen"
      :loading="loading"
    >
      <template #customLoading>
        <ca-alert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Antragsdaten..."
        />
      </template>
    </ca-header>
    <form>
      <div class="container-fluid px-4 mb-4">
        <div
          class="row"
        >
          <div class="col-12 col-xl-8 col-xxl-6">
            <Card>
              <DetailData
                v-if="!loading"
                :order="order"
                :loading="loading"
                :customer="customer"
              />
            </Card>
          </div>
        </div>
      </div>
      <HSSOrderChange
        v-if="!loading"
        :order="order"
        @editDisable="(value) => {
          knockoutMotorstaerke = value
        }"
      >
        <template #buttons>
          <button
            type="button"
            class="btn text-primary"
            @click="abort"
          >
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="knockoutMotorstaerke || pending"
            @click="save"
          >
            Vertrag erstellen
            <i
              v-if="pending"
              class="fas fa-circle-notch fa-spin"
            />
          </button>
        </template>
      </HSSOrderChange>
      <p
        v-if="showValidationError"
        class="text-danger text-right"
      >
        Bitte überprüfe deine Angaben.
      </p>
    </form>
  </div>
</template>
<script>
import CaHeader from '@/components/Layout/Header.vue'
import DetailData from './components/DetailData.vue'
import feathers from '@/api'
import HSSOrderChange from '@/components/Forms/Order/HSSOrderChange.vue'
import Card from '@/components/Card.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: { CaHeader, DetailData, HSSOrderChange, Card },
  data () {
    return {
      loading: false,
      order: null,
      client: {},
      customer: {},
      error: null,
      hardknockouts: {},
      knockoutMotorstaerke: false,
      pending: false,
      showValidationError: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.order = await this.$store.dispatch('hs-order/get', this.$route.params.orderId)
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        const motorstaerkeMax = (await feathers.service('hsdata-fz-motorstaerke').find({
          query: {
            von: { $lte: this.order.carData.motorstaerke },
            bis: { $gte: this.order.carData.motorstaerke }
          }
        })).data[0]
        this.order.motorstaerkeMax = motorstaerkeMax.bis
        this.client = clientReq.data[0]
        this.customer = this.order.customer
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async abort () {
      this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
    },
    async save () {
      this.showValidationError = !await this.$validator.validateAll() || !this.order.product
      if (this.showValidationError) return
      this.pending = true
      try {
        await feathers.service('hs-order-process-vertrag-erstellen').create(this.order)
      } catch (e) {
        console.error(e)
      } finally {
        this.pending = false
        this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
      }
    }
  }
}
</script>
