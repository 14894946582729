<template>
  <div class="container-fluid px-4">
    <div
      class="row"
    >
      <div class="col-12 col-xl-8 col-xxl-6">
        <ca-card>
          <template #header>
            <h4 class="mb-0">
              Weitere Daten
            </h4>
          </template>
          <template #body>
            <form>
              <VersicherungsbeginnKennzeichenFIN
                :order="order"
                :required="required"
              />
              <ChangeWkzInput
                :order="order"
                :required="required"
              />
            </form>
          </template>
        </ca-card>
        <div
          class="form-row justify-content-end align-items-center mt-3"
        >
          <p class="mr-2 mb-0">
            *Pflichtfeld
          </p>
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaCard from '@/components/Card.vue'
import VersicherungsbeginnKennzeichenFIN from './VersicherungsbeginnKennzeichenFIN.vue'
import ChangeWkzInput from './components/ChangeWkzInput.vue'

export default {
  components: {
    CaCard,
    VersicherungsbeginnKennzeichenFIN,
    ChangeWkzInput
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    }
  }
}
</script>
